.githubImage {
  padding-top: 7px;
}
.mailImage {
  padding-top: 11px;
}
.linkedInImage {
  padding-top: 2px;
}

.menu-item,
.menu-open-button {
  background: #ffffff;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  margin-left: -40px;
  position: absolute;
  top: 20px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}

.menu-open {
  opacity: 0%;
}

.hamburger {
  width: 25px;
  height: 3px;
  background: #202020;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  transition: transform 200ms;
}

.hamburger-1 {
  transform: translate3d(0, -8px, 0);
}

.hamburger-2 {
  transform: translate3d(0, 0, 0);
}

.hamburger-3 {
  transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .hamburger-1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}
.menu-open:checked + .menu-open-button .hamburger-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}
.menu-open:checked + .menu-open-button .hamburger-3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  font-size: 20px;
}

.menu-item:hover {
  background: rgb(107, 193, 199);
  color: #202020;
}

.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}
.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 160ms;
  transform: translate3d(-114.40705px, 11.66307px, 0);
}
.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 240ms;
  transform: translate3d(-77.04956px, 85.37192px, 0);
}
.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 320ms;
  transform: translate3d(0.09158px, 114.99996px, 0);
}
