
ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: space-evenly;
}

h2 {
  padding: .4rem;
  text-align: center;
}

li {
  margin: .5em;
}

.project-card {
  background-color: #FFFFFF;
  color: black;
  width: 20em;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  border-radius: 3px;
}

.project-card img{
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.25));
  max-width: 100%;
  max-height: 200px; 
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.project-card > a{
  border: 1px solid #202020;
  color: #202020;
  transition: all .44s;
  margin: 10px;
  padding: 5px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

.project-card > a:hover{
  background-color: #202020;
  color: #FFFFFF;
}
