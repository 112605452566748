.name-title {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  line-height: 0px;
  margin-bottom: 80px;
  font-size: large;
}

.nav-links {
  display: block;
  margin: 5px;
  flex-direction: column;
  justify-content: space-around;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 2rem;
  color: inherit;
  text-align: inherit;
  cursor: pointer;
}

.nav-links:hover {
  font-weight: bold;
}

.nav-links.active,
.nav-links:hover {
  font-weight: bold;
}

.nav-container {
  display: flex;
  align-items: center;
  height: 60px;
}
