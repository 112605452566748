.about-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}

.about-image {
  width: 200px;
  height: 200px;
}
