.construction {
  display: flex;
  justify-content: center;
  align-content: center;
}

.name-title {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  line-height: 0px;
  font-size: large;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 100%;
}

body {
  margin: 20px;
  padding: 20px;
  font-family: sans-serif;
  background: #202020;
  color: white;
  display: flex;
  flex-direction: column;
}
